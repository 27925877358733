import React from "react";

const ContactUs = () => {
  const contactInfo = [
    { type: "Phone", details: ["+91 93107 40740 (Mobile)", "+91 93137 47464", "+91 84597 47454" ,"+91 11 3501 2529 (Main)", "+91 11 3501 2502 (Home)", "+91 11 3501 2501 (Work Fax)", "+91 11 3501 2530 (Home Fax)", "+91 11 4552 1751 (Work)"] },
    { type: "Email", details: ["srvpathlab1984@gmail.com"] },
    { type: "Address", details: ["B-2/28A, Yamuna Vihar, Near Bhajanpura Petrol Pump, Delhi, India"] },
  ];

  return (
    <div className="min-h-screen bg-gray-100 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-lg overflow-hidden">
        {/* Header */}
        <div className="bg-indigo-600 px-6 py-4">
          <h1 className="text-3xl font-semibold text-white">Contact Us</h1>
          <p className="mt-2 text-indigo-200">
            We're here to help! Reach out to us via phone, email, or visit our office.
          </p>
        </div>

        {/* Contact Information */}
        <div className="p-6">
          <div className="space-y-8">
            {contactInfo.map((info, index) => (
              <div key={index} className="space-y-4">
                <h2 className="text-xl font-semibold text-gray-800">{info.type}</h2>
                <ul className="space-y-2">
                  {info.details.map((detail, idx) => (
                    <li key={idx} className="text-gray-600 flex items-center">
                      <span className="mr-2">
                        {info.type === "Phone" && (
                          <svg
                            className="w-5 h-5"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                            />
                          </svg>
                        )}
                        {info.type === "Email" && (
                          <svg
                            className="w-5 h-5"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                            />
                          </svg>
                        )}
                        {info.type === "Address" && (
                          <svg
                            className="w-5 h-5"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                          </svg>
                        )}
                      </span>
                      {detail}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        {/* Call to Action */}
        <div className="bg-gray-50 px-6 py-4 border-t border-gray-200">
          <p className="text-center text-gray-600">
            Have more questions?{" "}
            <a href="mailto:support@example.com" className="text-indigo-600 hover:underline">
              Send us an email
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;