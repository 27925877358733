// import React, { useEffect, useState } from "react";
// import API_BASE_URL from "../../config";

// const MyOrders = () => {
//   const [orders, setOrders] = useState([]);
//   const patientId = localStorage.getItem("patientId");
//   const host = API_BASE_URL;

//   useEffect(() => {
//     const fetchOrders = async () => {
//       try {
//         const response = await fetch(`${host}/api/orders/patient/${patientId}`);
//         const data = await response.json();
//         setOrders(data);
//       } catch (error) {
//         console.error("Error fetching orders:", error);
//       }
//     };
  
//     fetchOrders();
//   }, [patientId]);
  

//   const getStatusStyle = (status) => {
//     switch (status) {
//       case "Pending":
//         return "bg-yellow-100 text-yellow-800";
//       case "Completed":
//         return "bg-green-100 text-green-800";
//       case "Cancelled":
//         return "bg-red-100 text-red-800";
//       default:
//         return "bg-gray-100 text-gray-800";
//     }
//   };

//   return (
//     <div className="w-[99%] mx-auto px-4 sm:px-6 lg:px-8 py-8">
//       <div className="bg-white rounded-xl shadow-lg overflow-hidden">
//         <div className="px-6 py-4 border-b border-gray-200">
//           <h2 className="text-2xl font-semibold text-gray-800">My Orders</h2>
//         </div>

//         {orders.length === 0 ? (
//           <div className="p-6 text-center text-gray-500">No orders found</div>
//         ) : (
//           <>
//             {/* Table for larger screens */}
//             <div className="hidden md:block overflow-x-auto">
//               <table className="w-full">
//                 <thead className="bg-gray-50">
//                   <tr>
//                     {["Order Date", "Items", "Total Amount", "Status", "Payment Proof"].map((header) => (
//                       <th
//                         key={header}
//                         className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
//                       >
//                         {header}
//                       </th>
//                     ))}
//                   </tr>
//                 </thead>
//                 <tbody className="bg-white divide-y divide-gray-200">
//                   {orders.map((order) => (
//                     <tr key={order._id} className="hover:bg-gray-50 transition-colors">
//                       <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
//                         {new Date(order.createdAt).toLocaleDateString("en-IN", {
//                           day: "numeric",
//                           month: "short",
//                           year: "numeric",
//                         })}
//                       </td>
//                       <td className="px-6 py-4 text-sm text-gray-900 max-w-xs">
//                         <ul className="list-disc list-inside space-y-1">
//                           {order.cartItems.map((item, index) => (
//                             <li key={index} className="truncate">
//                               {item.test?.name || item.program?.name}
//                             </li>
//                           ))}
//                         </ul>
//                       </td>
//                       <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
//                         ₹{order.totalAmount.toLocaleString("en-IN")}
//                       </td>
//                       <td className="px-6 py-4 whitespace-nowrap">
//                         <span
//                           className={`px-3 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusStyle(
//                             order.status
//                           )}`}
//                         >
//                           {order.status}
//                         </span>
//                       </td>
//                       <td className="px-6 py-4 whitespace-nowrap text-sm">
//                         {order.paymentImage ? (
//                           <a
//                             href={`${host}/uploads/orders/${order.paymentImage}`}
//                             download
//                             className="text-indigo-600 hover:text-indigo-900 font-medium hover:underline flex items-center"
//                           >
//                             <svg
//                               className="w-4 h-4 mr-1"
//                               fill="none"
//                               stroke="currentColor"
//                               viewBox="0 0 24 24"
//                               xmlns="http://www.w3.org/2000/svg"
//                             >
//                               <path
//                                 strokeLinecap="round"
//                                 strokeLinejoin="round"
//                                 strokeWidth={2}
//                                 d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
//                               />
//                             </svg>
//                             Download
//                           </a>
//                         ) : (
//                           <span className="text-gray-400">Not Provided</span>
//                         )}
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </div>

//             {/* Cards for smaller screens */}
//             <div className="md:hidden space-y-4 p-4">
//               {orders.map((order) => (
//                 <div key={order._id} className="bg-white shadow-md rounded-lg p-4">
//                   <div className="space-y-2">
//                     <div className="flex justify-between items-center">
//                       <span className="text-sm font-medium text-gray-500">Order Date</span>
//                       <span className="text-sm text-gray-900">
//                         {new Date(order.createdAt).toLocaleDateString("en-IN", {
//                           day: "numeric",
//                           month: "short",
//                           year: "numeric",
//                         })}
//                       </span>
//                     </div>
//                     <div className="flex justify-between items-center">
//                       <span className="text-sm font-medium text-gray-500">Items</span>
//                       <ul className="text-sm text-gray-900">
//                         {order.cartItems.map((item, index) => (
//                           <li key={index} className="truncate">
//                             {item.test?.name || item.program?.name}
//                           </li>
//                         ))}
//                       </ul>
//                     </div>
//                     <div className="flex justify-between items-center">
//                       <span className="text-sm font-medium text-gray-500">Total Amount</span>
//                       <span className="text-sm text-gray-900">
//                         ₹{order.totalAmount.toLocaleString("en-IN")}
//                       </span>
//                     </div>
//                     <div className="flex justify-between items-center">
//                       <span className="text-sm font-medium text-gray-500">Status</span>
//                       <span
//                         className={`px-3 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusStyle(
//                           order.status
//                         )}`}
//                       >
//                         {order.status}
//                       </span>
//                     </div>
//                     <div className="flex justify-between items-center">
//                       <span className="text-sm font-medium text-gray-500">Payment Proof</span>
//                       {order.paymentImage ? (
//                         <a
//                           href={`${host}/uploads/orders/${order.paymentImage}`}
//                           download
//                           className="text-indigo-600 hover:text-indigo-900 font-medium hover:underline flex items-center"
//                         >
//                           <svg
//                             className="w-4 h-4 mr-1"
//                             fill="none"
//                             stroke="currentColor"
//                             viewBox="0 0 24 24"
//                             xmlns="http://www.w3.org/2000/svg"
//                           >
//                             <path
//                               strokeLinecap="round"
//                               strokeLinejoin="round"
//                               strokeWidth={2}
//                               d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
//                             />
//                           </svg>
//                           Download
//                         </a>
//                       ) : (
//                         <span className="text-gray-400">Not Provided</span>
//                       )}
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// export default MyOrders;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import API_BASE_URL from "../../config";

const MyOrders = () => {
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate
  const patientId = localStorage.getItem("patientId");
  const host = API_BASE_URL;

  useEffect(() => {
    if (!patientId) {
      navigate("/register"); // Redirect to login if not logged in
      return;
    }

    const fetchOrders = async () => {
      try {
        const response = await fetch(`${host}/api/orders/patient/${patientId}`);
        const data = await response.json();
        setOrders(data);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, [patientId, navigate]); // Include navigate in dependency array

  const getStatusStyle = (status) => {
    switch (status) {
      case "Pending":
        return "bg-yellow-100 text-yellow-800";
      case "Completed":
        return "bg-green-100 text-green-800";
      case "Cancelled":
        return "bg-red-100 text-red-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  return (
    <div className="w-[99%] mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="bg-white rounded-xl shadow-lg overflow-hidden">
        <div className="px-6 py-4 border-b border-gray-200">
          <h2 className="text-2xl font-semibold text-gray-800">My Orders</h2>
        </div>

        {orders.length === 0 ? (
          <div className="p-6 text-center text-gray-500">No orders found</div>
        ) : (
          <>
            {/* Table for larger screens */}
            <div className="hidden md:block overflow-x-auto">
              <table className="w-full">
                <thead className="bg-gray-50">
                  <tr>
                    {["Order Date", "Items", "Total Amount", "Status", "Payment Proof"].map((header) => (
                      <th
                        key={header}
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {orders.map((order) => (
                    <tr key={order._id} className="hover:bg-gray-50 transition-colors">
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {new Date(order.createdAt).toLocaleDateString("en-IN", {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        })}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-900 max-w-xs">
                        <ul className="list-disc list-inside space-y-1">
                          {order.cartItems.map((item, index) => (
                            <li key={index} className="truncate">
                              {item.test?.name || item.program?.name}
                            </li>
                          ))}
                        </ul>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        ₹{order.totalAmount.toLocaleString("en-IN")}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span
                          className={`px-3 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusStyle(
                            order.status
                          )}`}
                        >
                          {order.status}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        {order.paymentImage ? (
                          <a
                            href={`${host}/uploads/orders/${order.paymentImage}`}
                            download
                            className="text-indigo-600 hover:text-indigo-900 font-medium hover:underline flex items-center"
                          >
                            <svg
                              className="w-4 h-4 mr-1"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                              />
                            </svg>
                            Download
                          </a>
                        ) : (
                          <span className="text-gray-400">Not Provided</span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Cards for smaller screens */}
            <div className="md:hidden space-y-4 p-4">
              {orders.map((order) => (
                <div key={order._id} className="bg-white shadow-md rounded-lg p-4">
                  <div className="space-y-2">
                    <div className="flex justify-between items-center">
                      <span className="text-sm font-medium text-gray-500">Order Date</span>
                      <span className="text-sm text-gray-900">
                        {new Date(order.createdAt).toLocaleDateString("en-IN", {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        })}
                      </span>
                    </div>
                    <div className="flex justify-between items-center">
                      <span className="text-sm font-medium text-gray-500">Items</span>
                      <ul className="text-sm text-gray-900">
                        {order.cartItems.map((item, index) => (
                          <li key={index} className="truncate">
                            {item.test?.name || item.program?.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="flex justify-between items-center">
                      <span className="text-sm font-medium text-gray-500">Total Amount</span>
                      <span className="text-sm text-gray-900">
                        ₹{order.totalAmount.toLocaleString("en-IN")}
                      </span>
                    </div>
                    <div className="flex justify-between items-center">
                      <span className="text-sm font-medium text-gray-500">Status</span>
                      <span
                        className={`px-3 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusStyle(
                          order.status
                        )}`}
                      >
                        {order.status}
                      </span>
                    </div>
                    <div className="flex justify-between items-center">
                      <span className="text-sm font-medium text-gray-500">Payment Proof</span>
                      {order.paymentImage ? (
                        <a
                          href={`${host}/uploads/orders/${order.paymentImage}`}
                          download
                          className="text-indigo-600 hover:text-indigo-900 font-medium hover:underline flex items-center"
                        >
                          <svg
                            className="w-4 h-4 mr-1"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                            />
                          </svg>
                          Download
                        </a>
                      ) : (
                        <span className="text-gray-400">Not Provided</span>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MyOrders;
